<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'

export default {
  name: 'routing',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/route/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '路由Id',
          type: 'input',
          key: 'routeId'
        },
        {
          name: '路由名称',
          type: 'input',
          key: 'routeName'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: '主键',
          type: 'lt-100',
          isId: true,
          sorter: (a, b) => a.id.length - b.id.length
        },
        {
          dataIndex: 'routeId',
          title: '路由Id',
          type: 'lt-90',
          sorter: (a, b) => a.routeId.length - b.routeId.length
        },
        {
          dataIndex: 'routeName',
          title: '路由名称',
          type: 'lt-100',
          sorter: (a, b) => a.routeName.length - b.routeName.length
        },
        {
          dataIndex: 'uri',
          title: '请求uri',
          type: 'lt-100',
          sorter: (a, b) => a.uri.length - b.uri.length
        },
        {
          dataIndex: 'createTime',
          title: '创建时间',
          type: 'lt-100',
          sorter: (a, b) => a.createTime.length - b.createTime.length
        },
        {
          dataIndex: 'filters',
          title: '过滤器',
          type: 'lt-100',
          isId: true,
          sorter: (a, b) => a.filters.length - b.filters.length
        },
        {
          dataIndex: 'order',
          title: '排序值',
          type: 'lt-100',
          isId: true,
          sorter: (a, b) => a.order - b.order
        },
        {
          dataIndex: 'predicates',
          title: '断言',
          type: 'lt-100',
          isId: true,
          sorter: (a, b) => a.predicates.length - b.predicates.length
        },
        {
          dataIndex: 'updateTime',
          title: '修改时间',
          type: 'lt-100',
          sorter: (a, b) => a.updateTime.length - b.updateTime.length
        },
        {
          dataIndex: 'delFlag',
          title: '状态',
          width: '60px',
          type: 'badge',
          isId: true,
          onExport: records => {
            return ['正常', '已删除'][records]
          },
          filters: [
            {
              text: '正常',
              value: '0'
            },
            {
              text: '已删除',
              value: '1'
            }
          ],
          onFilter: (value, record) => record.delFlag == value,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '正常' : '失效',
              color: data == 0 ? 'green' : 'red'
            }
          }
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: '编辑',
                onClick: () => this.$router.push(`/system/routingDetail?id=${records.id}`)
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.del.call(this, {
                    url: `/route/${records.id}`
                  })
                }
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/system/routingDetail')
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
